html {
  scroll-padding-top: 250px;
}

fieldset {
  margin-bottom: 16px !important;
}

.App {
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-height: 110vh;
}

main {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Modal */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  overflow-y: auto;
  z-index: 2;
  transition:
    opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out;
}

.modal-container {
  margin-top: 80px;
  margin-bottom: 40px;
  height: fit-content;
  background: white;
  position: relative;
  z-index: 3;
}

.componentName {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}
.componentName input {
  width: 50%;
  margin-left: 10px;
}
.componentTable table {
  border: solid 1px;
  margin-bottom: 20px;
  width: 800px;
}
.componentTable .row {
  padding: 10px;
}

.tableHeading {
  background-color: #d3d3d3;
  font-weight: bold;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.tableBody {
  padding-top: 10px;
}

.tableBody .col-10 {
  border-bottom: solid 0.5px #d3d3d3;
  padding-bottom: 10px;
}

.name {
  font-weight: bold;
}

.button-link {
  color: maroon;
  text-decoration: underline;
}

.button-link:hover {
  text-decoration: none;
}

.button-link.fw-bold {
  text-decoration-thickness: 2px;
}

.newRuleButton {
  float: right;
}
.newReqButton {
  float: right;
  margin-right: 10px;
}

.editButton {
  cursor: pointer;
  margin-right: 10px;
  color: maroon;
}
.deleteButton {
  cursor: pointer;
}
.inputTextbox {
  width: 80%;
  height: 40px;
}
.textGrayBackground {
  background-color: #d3d3d3;
  padding: 5px;
}
.gsCode {
  background-color: #191919;
  color: #fff;
  padding: 2px 8px;
}

.tab {
  transition: border 0.1s ease;
}

.active-tab {
  border-bottom: solid 4px #ffc627;
}

.inactive-tab:hover {
  border-bottom: solid 4px #bfbfbf !important;
}

.component-settings-title {
  text-decoration: underline 2px rgba(140, 29, 64, 0);
  transition:
    color 0.2s ease,
    text-decoration-color 0.2s ease;
}

.component-settings-title:hover {
  color: #8c1d40;
  text-decoration-color: rgba(140, 29, 64, 1);
}

.component-settings-title .edit-icon {
  color: #8c1d40;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.2s ease,
    visibility 0.2s ease;
}

.component-settings-title:hover .edit-icon {
  opacity: 1;
  visibility: visible;
}

.mini-modal-container {
  position: absolute;
  top: -25px;
  left: -25px;
  z-index: 1;
  box-shadow: 0px 3px 6px #00000029;
  transition:
    opacity 0.2s ease,
    visibility 0.2s ease;
}

.mini-menu-container {
  position: absolute;
  top: 37px;
  right: 0;
  z-index: 1;
  box-shadow: 0px 3px 6px #00000029;
  transition:
    opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out;
}

.sidebar-menu .mini-menu-container {
  position: absolute;
  top: 28px;
  right: 0;
  z-index: 1;
  box-shadow: 0px 3px 6px #00000029;
  transition:
    opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out;
}

.history-menu-container {
  position: absolute;
  top: 28px;
  z-index: 1;
  box-shadow: 0px 3px 6px #00000029;
  transition:
    opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out;
}

.template-name-input {
  position: absolute;
  top: -5px;
  left: -5px;
  z-index: 1;
  transition:
    opacity 0.2s ease,
    visibility 0.2s ease;
}

.dropdown .dropdown-menu {
  display: block;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.2s ease,
    visibility 0.2s ease;
  filter: drop-shadow(0px 3px 6px #00000029);
  border: solid 1px #d0d0d0;
}

.dropdown.show .dropdown-menu {
  opacity: 1;
  visibility: visible;
  z-index: 1;
}

.dropdown.add-component .dropdown-menu {
  border: none;
  border-radius: 8px;
}

.dropdown.text-options .dropdown-menu {
  border-radius: 0 0 8px 8px;
}

.dropdown.add-component .dropdown-menu .dropdown-item,
.dropdown.add-component
  .dropdown-menu
  .dropdown-item-text.add-component-folder {
  border-left: solid 1px #d0d0d0;
  border-right: solid 1px #d0d0d0;
}

.dropdown.add-component .dropdown-menu .dropdown-item-text:has(input),
.dropdown.text-options .dropdown-menu,
.dropdown.add-component
  .dropdown-menu
  .dropdown-item-text.add-component-folder {
  padding: 0 !important;
}

.dropdown.add-component .dropdown-menu .dropdown-item-text input {
  border-radius: 8px 8px 0 0;
}

.validation input {
  width: 75px;
  border: 1px solid #d0d0d0;
}

.validation select {
  width: 120px;
  border: 1px solid #d0d0d0;
}

input,
textarea,
select {
  border: 1px solid #d0d0d0;
  padding: 8px;
  line-height: normal;
}

select:disabled {
  background-color: #e8e8e8;
  border-color: #e8e8e8;
}

.template-component input {
  width: 93px;
}

.recent-template {
  font-size: 14px;
  width: 252px;
  height: 175px;
}

.drop-folder {
  transition: background-color 0.2s ease;
}

.library-component {
  transition: background-color 0.1s ease;
}

.library-component:hover {
  background-color: #e8e8e8;
}

.library-component .ellipsis,
.library-folder .folder-ellipsis {
  visibility: hidden;
}

.library-component:hover .ellipsis,
.library-folder:hover .folder-ellipsis {
  visibility: visible;
}

.inactive-nav {
  border-color: transparent !important;
  transition: border-color 0.2s ease;
}

.inactive-nav:hover {
  border-color: #bfbfbf !important;
}

.routes-wrap {
  top: 139px;
  /* top: 26px; */
  transition: top 0.5s cubic-bezier(0.19, 1, 0.19, 1) 0s;
}

.routes-wrap.scroll-down {
  top: 89px;
  /* top: 0; */
}

.universal-nav {
  height: 26px;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.19, 1) 0s;
}

.scrollbox {
  overflow-y: auto;
  /* visibility: hidden; */
  height: 100%;
}

.scrollbox-content,
.scrollbox:hover {
  visibility: visible;
}

.vertical-ellipsis {
  transition: background-color 0.2s ease;
}

.vertical-ellipsis:hover {
  background-color: #d0d0d0;
}

.mini-menu-option {
  transition: background-color 0.2s ease;
}

.mini-menu-option:hover {
  background-color: #d0d0d0;
}

.routes-wrap .sidebar-menu {
  top: 139px;
  /* top: 26px; */
  bottom: 0;
  z-index: 1;
  transition: top 0.5s cubic-bezier(0.19, 1, 0.19, 1) 0s;

  /* transition: height 0.5s cubic-bezier(0.19, 1, 0.19, 1); */
}

.routes-wrap.scroll-down .sidebar-menu {
  top: 89px;
  /* top: 0; */
}

#asuHeader {
  z-index: 2;
}

.border-8 {
  border-width: 8px !important;
}

.bg-asu-pink {
  background-color: #e74973;
}

.border-asu-pink {
  border-color: #e74973 !important;
}

.bg-asu-green {
  background-color: #78be20;
}

.underline-hover {
  text-decoration: none;
}

.underline-hover:hover {
  text-decoration: underline;
}

.filters .btn.btn-white {
  border: 1px solid #747474;
}

.filters form.uds-form fieldset .form-check {
  margin-top: 0;
  margin-bottom: 0;
}

.filters .dropdown.show .btn {
  background-color: #8c1d40;
  border-color: #8c1d40;
  color: white;
}

.filters .dropdown .btn .fa-chevron-down {
  transition: rotate 0.2s ease-in-out;
}

.filters .dropdown.show .btn .fa-chevron-down {
  rotate: 180deg;
}

.summary-row-expansion {
  height: 0;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  transition:
    height 0.2s ease-in-out,
    visibility 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
}

.summary-row-expansion.expansion-open {
  height: 108px;
  visibility: visible;
  opacity: 100;
}

.summary-row-head .col-auto {
  transition: font-weight 0.2s ease-in-out;
}

.fa-chevron-down {
  transition: rotate 0.2s ease-in-out;
}

.fa-chevron-down.rotate-180,
.dropdown.show .fa-chevron-down {
  rotate: 180deg;
}

.AND {
  padding: 2px 8px;
  height: 20px !important;
  width: 50px !important;
  text-align: center;
  margin: 0 8px 4px 0px;
  font-size: 14px;
  background-color: #e8e8e8 !important;
  line-height: normal;
}

.OR {
  padding: 2px 12px;
  width: 50px !important;
  text-align: center;
  margin: 0 9px 4px 0px;
  font-size: 14px;
  color: white;
  background-color: #484848 !important;
  line-height: normal;
}

.or {
  padding: 1px 15px;
  width: 50px !important;
  text-align: center;
  margin: 4px 8px;
  font-size: 14px;
  border: 1px solid #bfbfbf;
  line-height: normal;
}

.text-option-row {
  transition: background-color 0.2s ease-in-out;
}

.text-option-row:hover {
  background-color: #e8e8e8;
}

.new-text-option {
  transition:
    visibility 0.2s ease-in-out,
    opacity 0.2s ease-in-out,
    height 0.2s ease-in-out;
}

/* #asuHeader > div:not(.universal-nav) {
  display: none;
} */

.skeleton {
  border-radius: 10px;
  display: inline-block;
  line-height: 100%;
  width: 100%;
  background-color: #ffffff;
  background-size: 1000px 1000px;
  background-image: linear-gradient(
    100deg,
    #e8e8e8 20%,
    #fafafa 50%,
    #e8e8e8 60%
  );
  animation: loadingAnimation 1.5s linear infinite forwards;
}

@keyframes loadingAnimation {
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 500px 0;
  }
}

.skeleton.circle {
  border-radius: 50%;
}

.modal-dialog {
  max-width: min-content;
}

.modal-content {
  border-radius: 0;
}

.modal-open #asuHeader {
  padding-right: 15px;
}

.modal-open .template-header {
  width: calc(80% - 13px) !important;
}

.modal-open .sidebar-menu {
  width: calc(20% - 3px) !important;
}

/* Hoverable on Checksheet */

.hoverable {
  position: relative;
  box-sizing: border-box;
}

.hoverable:hover .hover-text {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

.hoverable:hover .fa-grip-vertical,
.hoverable:hover .fa-trash {
  color: #484848 !important; /* Change this to the desired hover color for the icon */
}

.hoverable::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: transparent;
  transition:
    background-color 0.3s,
    width 0.3s;
}

.hoverable:hover::before {
  width: 5px; /* Width when hovered */
  background-color: #8c1d40; /* Change to desired color */
}

.fa-grip-vertical {
  color: #bfbfbf !important;
  margin-top: 3px;
}

.fa-trash {
  color: #8c1d40 !important;
  margin-top: 3px;
}

.fa-trash-active {
  color: #8c1d40 !important;
}

.btn .fas.fa-check,
.btn .fas.fa-xmark,
.btn .fas.fa-caret-left,
.btn .fas.fa-caret-right {
  margin: 0 8px;
}

.requirement-drag {
  box-shadow: none !important;
}

.requirement-drag.focus-off {
  outline: 0 !important;
}

.requirement-drag:focus {
  outline: black solid 2px;
}

/* Subplan Activation Custom Accordion */
.custom-accordion .accordion .accordion-item {
  border-top: none;
}

.custom-accordion .accordion-item {
  border-left: none;
  border-right: none;
}

.custom-accordion .row:hover {
  background-color: #f5f5f5; /* You can change this color as needed */
  cursor: pointer; /* Optional: adds a pointer cursor */
}

.text-success-custom {
  color: #446d12;
}

.text-info-custom {
  color: #126877;
}

.text-warning-custom {
  color: #bd4800;
}

.text-danger-custom {
  color: #b72a2a;
}

.bg-success-custom {
  background-color: #e9f5db;
}

.bg-info-custom {
  background-color: #d6f0fa;
}

.bg-warning-custom {
  background-color: #ffeade;
}

.bg-danger-custom {
  background-color: #f7dddd;
}

.border-success-custom {
  border-color: #446d12 !important ;
}

.subplan-tag {
  color: #ffffff !important;
  background-color: #78be20;
  padding: 2px 8px;
}

.dropdown-container #dropdown-2 {
  width: fit-content;
}

.program-row {
  background-color: transparent;
  transition: background-color 0.2s ease;
}

.program-row:hover {
  background-color: #e8e8e8;
}

.program-row:has(.program-row-checkbox:checked) {
  background-color: #d6f0fa;
}

.program-row-checkbox {
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.2s ease,
    visibility 0.2s ease;
}

.program-row:hover .program-row-checkbox,
.program-row-checkbox:checked {
  opacity: 1;
  visibility: visible;
}

.program-list-header {
  transition:
    opacity 0.2s ease,
    visibility 0.2s ease;
}

input[type='checkbox']:not(.toggle-switch) {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  width: 20px;
  height: 20px;
  border: 1px solid #747474;
  display: grid;
  place-content: center;
}

input[type='checkbox']:not(.toggle-switch)::before {
  content: '';
  width: 20px;
  height: 20px;
  cursor: pointer;
}

input[type='checkbox']:not(.toggle-switch):checked::before {
  content: url('data:image/svg+xml,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" class="svg-inline--fa fa-check fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="-70 -70 650 650"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>');
  background-color: white;
  border: 1px solid #747474;
}

input[type='checkbox']:not(.toggle-switch):focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}

/* Alternatively, use visibility for hidden but reserved layout space */
.hidden {
  visibility: hidden !important; /* Makes the element invisible but retains its layout space */
}

.checksheetCatalogChange thead tr {
  color: #f9f9f9;
  font-weight: bold;
}
.checksheetCatalogChange thead tr th {
  background-color: #191919 !important;
}

.checksheetCatalogChange .border-left {
  border-left: solid 1px darkgray;
}

.highlighted-row td {
  background-color: #a7d8f5 !important; /* Light gray background */
  /* Optional: Add more styles like bold text, color change, etc. */
}

.recall-requests-notification {
  box-shadow: 0px 3px 6px #00000029 !important;
}
